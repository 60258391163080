export const getError = (error) => {
  const errorMessage = "API Error, vuelva a intentar."

  
  if (error.name === "Fetch User") {
    return error.message
  }

  if (!error.response) {
    console.error(`API ${error.config.url} not found`)
    return errorMessage
  }
  if (process.env.NODE_ENV === "development") {
    console.error(error.response.data)
    console.error(error.response.status)
    console.error(error.response.headers)
  }
  if (error.response.data && error.response.data.errors) {
    let errors = error.response.data.errors
    return errors[Object.keys(errors)[0]]
  }

  return errorMessage;
}

export const can = (user, ability) => {
  let permissions = []
  if(user) {
    permissions = user.permissions.find((perm) => {
      return perm.name == ability
    })
    if (permissions)
      return true
  }
  return false
}

export function eliminarVacios(jsonx){
  for (var clave in jsonx) {
   if(typeof jsonx[clave] == 'string' || jsonx[clave] == null){
     if(jsonx[clave] == null || jsonx[clave] == ''){
       delete jsonx[clave]
     }
   } else if (typeof jsonx[clave] == 'object') {
     eliminarVacios(jsonx[clave])
    }
  }
 }

export function separarPorMiles(valor){
  if (valor == '' || valor == null) {
    return valor;
  }
  else{
  var valorSeparado = valor.toString().split('').reverse();

  for (let index = 3; index < valorSeparado.length; index+=4) {
    valorSeparado.splice(index, 0, '.');
  }

  let valorDefinitivo = valorSeparado.reverse().join('');

  return valorDefinitivo;

  }
}

export function printSection ( idElemento ){
// Se inserta un css especial para la impresión, 
// esto para mostrar un contenedor especial y ocultar el resto del contenido.
let style = document.createElement('style');
style.type = 'text/css';
style.appendChild(document.createTextNode("@media screen{#printSection{display:none}}@media print{#printSection,body{overflow:visible!important}body *{visibility:hidden}#printSection,#printSection *{visibility:visible}#printSection{position:absolute;left:0;top:0;right:0}#printSection table{width:100%!important}}"));
document.body.appendChild(style);

// Se busca si existe una sección para imprimir.
let printSection = document.getElementById('printSection');

// Si no existe, se crea e inserta en el body.
if (!printSection) {
    printSection = document.createElement('div');
    printSection.id = 'printSection';
    document.body.appendChild(printSection);
}

  // Se busca el contenido a imprimir con el id del elemento.
  var elemToPrint = document.getElementById(idElemento);

  if (elemToPrint) {
    printSection.innerHTML = '';
    let domClone = elemToPrint.cloneNode(true);
    printSection.appendChild(domClone);
    window.print();
  }
}

export function obtenerRandomEntero(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}