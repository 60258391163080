import * as API from "@/services/API";

export default {
    async getOrdenesTrabajo(page, dates_query, filters_query){
        return API.apiClient.get(
            `/ordenes-trabajo/lista-ot/?page=${page || 1}${dates_query ? dates_query : ''}${filters_query ? filters_query : ''}`
        );
    },
    async paginateOrdenesTrabajo(link, dates_query, filters_query) {
        return API.apiClient.get(
            link + `${dates_query ? dates_query : ''}` + `${filters_query ? filters_query : '' }`
        );
    },
    async getOrdenesTrabajoAnuladas(page, dates_query, filters_query) {
        return API.apiClient.get(
            `/papelera/ordenes-trabajo-anuladas/?page=${page || 1}${dates_query ? dates_query : ''}${filters_query ? filters_query : ''}`
        );
    },
    async paginateOrdenesTrabajoAnuladas(link, dates_query, filters_query) {
        return API.apiClient.get(
            link + `${dates_query ? dates_query : ''}` + `${filters_query ? filters_query : '' }`
        )
    },
    storeOrdenTrabajo(payload){
        return API.apiClient.post('/cotizaciones/generar-ot', payload);
    },
    ingresarOrdenTrabajo(payload){
        return API.apiClient.post('/cotizaciones/ingresar-ot', payload);
    },
    getOT(numOt){
        return API.apiClient.get(`/ordenes-trabajo/lista-ot/${numOt}`);
    },
    updateOT(ot, estadoOt) {
        return API.apiClient.put(`/ordenes-trabajo/lista-ot/${ot}/cambio-estado/${estadoOt}`);
    },
    nullateOT(ot) {
        return API.apiClient.put(`/ordenes-trabajo/lista-ot/${ot}/anulacion`);
    },
    getOTPendientes() {
        return API.apiClient.get(`/reportes/ot-pendientes`);
    },
    getReporteOtDiario(fecha) {
        return API.apiClient.get(`/reportes/caja-diaria/ordenes-trabajo/${fecha}`);
    },
    obtenerCodigoBarras(codigoOT) {
        return API.apiClient.get(`/cotizaciones/ingresar-ot/generar-codigo-barras/${codigoOT}`);
    },
    obtenerOTEntreFechas(fechaUno, fechaDos, { estadoLogOt = null, sucursal = null } = {}) {
        let cadenaEstadoLog = '';
        let cadenaSucursal = '';

        if (estadoLogOt != null) {
            cadenaEstadoLog = `/estado-log/${estadoLogOt}`;
        }
        if (sucursal != null) {
            cadenaSucursal = `/sucursal/${sucursal}`;
        }
    
        return API.apiClient.get(`/reportes/reporte-ot/${fechaUno}/${fechaDos}${cadenaEstadoLog}${cadenaSucursal}`);
    }
}